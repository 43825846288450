<template>
  <div class="page">
    <Navbar isBack="true" title="VIP券" />
    <div class="page_box">
      <div class="vip_level_nav">VIP优惠券</div>
      <div class="vip_level_dec">
        当每次升级VIP等级时，系统将自动发放相对应的充值优惠券到玩家的账户中，VIP等级对应的优惠券如下：
      </div>
      <div class="vip_level_table">
        <div class="vip_level_tr">
          <div class="vip_level_tb">VIP等级</div>
          <div class="vip_level_tb">VIP优惠券</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip0</div>
          <div class="vip_level_tb">无</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip1</div>
          <div class="vip_level_tb">普通满送优惠券礼包</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip2</div>
          <div class="vip_level_tb">普通满送优惠券礼包*2</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip3</div>
          <div class="vip_level_tb">普通满送优惠券礼包*3</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip4</div>
          <div class="vip_level_tb">高级满送优惠券礼包*1</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip5</div>
          <div class="vip_level_tb">高级满送优惠券礼包*2</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip6</div>
          <div class="vip_level_tb">高级满送优惠券礼包*3</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip7</div>
          <div class="vip_level_tb">特级满送优惠券礼包*1</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip8</div>
          <div class="vip_level_tb">特级满送优惠券礼包*2</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip9</div>
          <div class="vip_level_tb">特级满送优惠券礼包*3</div>
        </div>
        <div class="vip_level_tr">
          <div class="vip_level_tb">Vip10</div>
          <div class="vip_level_tb">至尊满送优惠券礼包*1</div>
        </div>
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon">普通满送优惠券礼包：</span> 22000送4000*1，109000送22000*1
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon">高级满送优惠券礼包：</span> 109000送24000*1，699000送210000*1
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon">特级满送优惠券礼包：</span> 1099000送350000*1，2199000送800000*1
      </div>
      <div class="vip_level_dec">
        <span class="vip_level_coupon"> 至尊满送优惠券礼包：</span>1099000送500000*3，2199000送1000000*3
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
$vip-color: #c37c00;
.page_box {
  padding: 0 0.22rem 1rem 0.22rem;
}
.vip_level_nav {
  margin: 0.4rem 0 0.1rem 0;
  padding: 0 0.3rem;
  line-height: 0.4rem;
  border: 1px solid $vip-color;
  color: $vip-color;
  border-radius: 1rem;
}
.vip_level_img {
  margin: 0.3rem 0;
}
.vip_level_dec {
  line-height: 0.4rem;
  font-size: 0.23rem;
}
.vip_level_coupon{
  font-weight: bold;
}
.vip_level_table {
  margin-top: 0.3rem;
  width: 100%;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.vip_level_tr {
  display: flex;
}
.vip_level_tr:nth-child(1) {
  background-color: #fde2b0;
}
.vip_level_tr:nth-child(1) .vip_level_tb {
  height: 0.9rem;
  color: $vip-color;
}
.vip_level_tb {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 0.6rem;
  font-size: 0.23rem;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>